import { Box, Card, Typography, Container, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from './LoginJWT';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
// import LanguageSwitcher from 'src/layouts/SidebarLayout/Sidebar/Buttons/LanguageSwitcher';

const MainContent = styled(Box)(() => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 50px;
  `
);

const TopWrapper = styled(Box)(() => `
    display: flex;
    width: 100%;
    flex: 1;
    padding: 20px;
  `
);

// const LanguageSwitcherContainer = styled(Box)(() => `
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 20px;
//   `
// );

function Login() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>BO Quickpass - Login</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Logo />
            <Card sx={{ mt: 3, px: 4, pt: 5, pb: 3 }}>
              <Box>
                <Typography variant="h2" sx={{ mb: 1 }}>
                  {t('Sign in')}
                </Typography>
                <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ mb: 3 }}>
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              {method === 'JWT' && <JWTLogin />}
              <br />
            </Card>
            {/* SE COMENTA PROVISIONALMENTE HASTA QUE TENGAMOS MANEJO DE MULTILENGUAJE */}
            {/* <LanguageSwitcherContainer>
              <LanguageSwitcher />
            </LanguageSwitcherContainer> */}
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default Login;