import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Box, Drawer, styled, Divider, useTheme, darken } from '@mui/material';
//import SidebarTopSection from './SidebarTopSection';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';
import Logo from 'src/components/LogoSign';
import { useSelector } from 'src/store';


const SidebarWrapper = styled(Box)(({ theme }) => `
    width: ${theme.sidebar.width};
    min-width: ${theme.sidebar.width};
    color: ${theme.colors.alpha.trueWhite[70]};
    position: relative;
    z-index: 7;
    height: 100%;
    padding-bottom: 61px;
  `
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { roles } = useSelector((state) => state.auth);
  const theme = useTheme();
  const rolesRedux = roles;

  return (
    <>
      <Drawer
        sx={{ boxShadow: `${theme.sidebar.boxShadow}` }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper 
          sx={{ 
            background: theme.palette.mode === 'dark' ? 
              theme.colors.alpha.white[100] : 
              darken(theme.colors.alpha.black[100], 0.5) 
            }}
          >
          <Scrollbar>
            <Box mt={3}>
              <Box mx={2} sx={{ width: 52 }}>
                <Logo />
              </Box>
            </Box>
            {/* <Divider sx={{ my: theme.spacing(3), mx: theme.spacing(2), background: theme.colors.alpha.trueWhite[10] }}/>
            <SidebarTopSection /> */}
            <Divider sx={{ my: theme.spacing(3), mx: theme.spacing(2), background: theme.colors.alpha.trueWhite[10] }}/>
             <SidebarMenu rolesRedux={rolesRedux} />
          </Scrollbar>
          <SidebarFooter />
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
