import { createSlice } from '@reduxjs/toolkit';
import { userNotification } from 'src/models/userNotification';
import { User, UserPoint, UserResponse } from 'src/models/user';
import { getUserById } from './userAction';


interface UserState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  users: User[];
  activeUser: User;
  userPaginated: User[];
  page: number;
  limit: number;
  total: number;
  changeUser: null;
  userPoints?: UserPoint[];
  changeUserPoint?: null;
  isLoadingUsersPoints: boolean;
  selectedUser: UserResponse | any;
  userFilters: any;
  userNotifiaction: userNotification;
  user: null,
  loadingEdit: boolean,
  error: null,
}

const initialState: UserState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  users: [],
  activeUser: null,
  userPaginated: [],
  page: 0,
  limit: 25,
  total: 0,
  changeUser: null,
  userPoints: [],
  changeUserPoint: null,
  isLoadingUsersPoints: false,
  selectedUser: {},
  userFilters: {
    filter: {
      search: null,
      birthdate: null,
      tags: null,
      roles: null,
      onlyDeleted: false,
    },
    order: {
      desc: false,
      field: 'init_date',
    },
    isVerify: true,
  },
  userNotifiaction:null,
  user: null,
  loadingEdit: false,
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoadingUsers: (state) => {
      state.isLoading = true;
    },
    setUsers: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    setUsersPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.total = action.payload.total;
      state.userPaginated = action.payload.users;
    },
    setChangeUser: (state, action) => {
      state.changeUser = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.statusCode;
      state.isErrorMessage = message;
    },
    setUserPoints: (state, action) => {
      state.isLoadingUsersPoints = false;
      state.userPoints = action.payload;
    },
    clearUserPoints:(state,action)=> {

      state.userPoints = action.payload;
    },
    startLoadingUsersPoints: (state) => {
      state.isLoadingUsersPoints = true;
    },
    setPoints: (state, action) => {
      state.changeUserPoint = action.payload;
      state.isLoading = false;
    },
    setErrorPoints: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingUsers: (state) => {
      state.isLoading = false;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload.data;
      state.isLoading = false;
      state.isErrorMessage = null;
    },
    resetSelectedUserState(state) {
      state.selectedUser = {};
    },
    stopLoadingUserPoints: (state) => {
      state.isLoadingUsersPoints = false;
    },
    setUserFilters: (state, action) => {
      state.userFilters = action.payload;
    },
    setNotification: (state, action) => {
      state.userNotifiaction = action.payload;
      state.isLoading = false;
    },
    getUserByIdRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getUserByIdSuccess: (state, action) => {
      // state.user = action.payload;
      state.selectedUser = action.payload;
      state.isLoading = false;
    },
    getUserByIdError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload
    },
    startLoadingEdit: (state) => {
      state.loadingEdit = true;
    },
    stopLoadingEdit: (state) => {
      state.loadingEdit = false;
    }
  }
});

export const {
  startLoadingUsers,
  setUsers,
  setUsersPaginated,
  setError,
  setChangeUser,
  setUserPoints,
  startLoadingUsersPoints,
  setPoints,
  setErrorPoints,
  stopLoadingUsers,
  setSelectedUser,
  stopLoadingUserPoints,
  setUserFilters,
  setNotification,
  resetSelectedUserState,
  getUserByIdRequest,
  getUserByIdSuccess,
  getUserByIdError,
  setActiveUser,
  clearUserPoints,
  startLoadingEdit,
  stopLoadingEdit
} = userSlice.actions;