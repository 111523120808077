import { useContext } from 'react';
import { Box, IconButton, Tooltip, styled } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo';

const HeaderWrapper = styled(Box)(({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    margin-top: ${theme.spacing(3)};
    position: relative;
    justify-content: space-between;
    width: 100%;
  `
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center" flexDirection="row">
      <Box display="flex" alignItems="center">
        <Box component="span">
          <Tooltip arrow title="Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              <MenuTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ paddingLeft: '10px' }}>
          <Logo />
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
