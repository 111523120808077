import { createSlice } from '@reduxjs/toolkit';
import { StatisticByClient } from 'src/models/statistic';

interface StatisticByClientState {
  isLoading: boolean;
  isLoadingDetails: boolean;
  isErrorMessage: string;
  statusCode: number;
  statisticsByClient: StatisticByClient[];
  activeStatisticByClient: StatisticByClient | {};
  statisticByClientPaginated: StatisticByClient[];
  pageCl: number;
  limitCl: number;
  changeStsByClient: null;
  filters: any;
  details: any;
  activeTab: number;
}

const initialState: StatisticByClientState = {
  isLoading: false,
  isLoadingDetails: false,
  statusCode: 201,
  isErrorMessage: '',
  statisticsByClient: [],
  activeStatisticByClient: {},
  statisticByClientPaginated: [],
  pageCl: 0,
  limitCl: 25,
  changeStsByClient: null,
  filters: {
    filter: {
      tktDates: {
        from: '',
        to: ''
      },
      place: '',
      state: '',
      onlyGift: false,
      user: '',
      tags: []
    },
    order: {
      orderBy: 'Qt',
      orderDesc: true
    }
  },
  details: [],
  activeTab: 1,
};

export const statisticByClientSlice = createSlice({
  name: 'statisticByClient',
  initialState,
  reducers: {
    startLoadingStatisticsByClient: (state) => {
      state.isLoading = true;
    },
    setStatisticsByClient: (state, action) => {
      state.isLoading = false;
      state.statisticsByClient = action.payload;
    },
    setStatisticsByClientPaginated: (state, action) => {
      state.isLoading = false;
      state.pageCl = action.payload.page;
      state.statisticByClientPaginated = action.payload.statisticsByClient.data;
    },
    setChangeStsByClient: (state, action) => {
      state.changeStsByClient = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    startLoadingDetails: (state) => {
      state.isLoadingDetails = true;
    },
    setDetails: (state, action) => {
      state.details = action.payload;
      state.isLoadingDetails = false;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    stopLoadingStatisticsByClient: (state) => {
      state.isLoading = false;
    },
    stopLoadingDetails: (state) => {
      state.isLoadingDetails = false;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingStatisticsByClient,
  setStatisticsByClient,
  setStatisticsByClientPaginated,
  setError,
  setChangeStsByClient,
  setFilters,
  setDetails,
  setActiveTab,
  stopLoadingStatisticsByClient,
  startLoadingDetails,
  stopLoadingDetails
} = statisticByClientSlice.actions;