//FirebaseQp
import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage';
import 'firebase/compat/auth';
import Config from '../config';

const firebaseAppQP = firebase.initializeApp(
  Config.firebase_QP_Config,
  'quick-pass-dev' // TODO revisar.
);
const storageQP = getStorage(firebaseAppQP);

export { firebaseAppQP, storageQP };
