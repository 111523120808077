const esJSON = {

  // Login & Recovery Password
  "Sign in": "Iniciar sesión",
  "Sign out": "Cerrar sesión",
  "Fill in the fields below to sign into your account.": "Complete los campos a continuación para iniciar sesión en su cuenta.",
  "Used only for the live preview demonstration!": "¡Se usa solo para la demostración previa en vivo!",
  "Use": "Utilice",
  "and password": "y la contraseña",
  "Email address": "Dirección de correo electrónico",
  "The email provided should be a valid email address": "La dirección de correo electrónico proporcionada debe ser válida",
  "The email field is required": "El campo de correo electrónico es obligatorio",
  "The password field is required": "El campo de contraseña es obligatorio",
  "You must agree to our terms and conditions": "Debes aceptar nuestros términos y condiciones",
  "Password": "Contraseña",
  "I accept the": "Acepto los",
  "terms and conditions": "términos y condiciones",
  "Lost password?": "¿Olvidaste tu contraseña?",
  "Recover Password": "Recuperar contraseña",
  "Enter the email used for registration to reset your password.": "Ingresa el correo electrónico utilizado para el registro para restablecer tu contraseña.",
  "Send me a new password": "Recibir una nueva contraseña",
  "Want to try to sign in again?": "¿Quieres intentar iniciar sesión de nuevo?",
  "Click here": "Haz clic aquí",
  "The password reset instructions have been sent to your email": "Las instrucciones para restablecer la contraseña se han enviado a tu correo electrónico.",
  "Check your email for further instructions": "Revisa tu correo electrónico para obtener más instrucciones.",
  "Continue to login": "Continuar con el inicio de sesión",
  "Language Switcher": "Selector de idiomas",
  "Rows per page:": "Filas por página:",
  "Login failed": "Error al iniciar sesión",
  "You do not have permissions to enter the app": "No tienes permisos para ingresar en la app.",
  // LOGIN ERRORS
  "The email address is not valid. Please check and try again.": "La dirección de correo electrónico no es válida. Por favor, revísala e inténtalo de nuevo.",
  "This user account has been disabled. Please contact support.": "Esta cuenta de usuario ha sido deshabilitada. Por favor, contacta con soporte.",
  "No account found with this email. Please register first.": "No se encontró una cuenta con este correo. Por favor, regístrate primero.",
  "Incorrect password. Please try again.": "Contraseña incorrecta. Por favor, inténtalo de nuevo.",
  "A problem ocurred when trying to send recovery password email": "Ocurrió un problema al intentar enviar el email. Por favor, inténtalo de nuevo.",

  //Filter
  "Search by": "Buscar por",
  "Last name": "Apellido",
  "Select": "Seleccionar",
  "Birth month": "Mes de nacimiento",
  "Day from": "Desde el dia",
  "Day to": "Hasta el dia",

  // Sidebar
  "Events": "Eventos",
  "Users": "Usuarios",
  "Roles": "Roles",
  "Notifications": "Notificaciones",
  "News": "Novedades",
  "Statistics": "Estadísticas",
  "Tags": "Tags",
  "Verified by": "Verificado por",
  "Date of verification": "Fecha de verificación",
  "Last name and name": "Apellido y nombre",
  "Birthdate": "Fecha de nacimiento",
  "Gender": "Género",
  "Phone": "Teléfono",
  "Registration date": "Fecha de registro",
  "Account": "Cuenta",

  // Events
  "Event management": "Administración de eventos",
  "All aspects related to application events can be managed from this page": "Todos los aspectos relacionados con los eventos de la aplicación se pueden gestionar desde esta página",
  "Create new event": "Crear nuevo evento",
  "Download list of events": "Descargar listado de eventos",
  "The event has been deleted successfully": "El evento se ha eliminado exitosamente",
  "The event has been copied successfully": "El evento se ha copiado exitosamente",
  "Search by name or email...": "Buscar por nombre o correo electrónico...",
  "We couldn't find any events matching your search criteria": "No pudimos encontrar ningún evento que coincida con sus criterios de búsqueda",
  "Name": "Nombre",
  "Verified sale": "Venta verificada",
  "Status": "Status",
  "Created by": "Creado por",
  "Modified by": "Modificado por",
  "Actions": "Acciones",
  "Manage tickets": "Gestionar tickets",
  "Event statistics": "Estadísticas del evento",
  "Copy event": "Copiar evento",
  "Complete the fields below to copy an event": "Complete los campos a continuación para copiar un evento",
  "Complete the fields below to edit an event": "Complete los campos a continuación para editar un evento",
  "Manage ticket types": "Gestionar tipos de tickets",
  "Edit Event": "Editar Evento",
  "Delete event": "Eliminar evento",
  "Do you really want to delete this event?": "¿Realmente deseas eliminar este evento?",
  "You won't be able to revert after deletion": "No podrás revertir después de la eliminación",
  "Delete": "Borrar",
  "Create Event": "Crear Evento",
  "Create New Event": "Crear nuevo evento",
  "Complete the fields below to create a new event": "Complete los campos a continuación para crear un nuevo evento",
  "Go back to the events list": "Regresar al listado de eventos",
  "Event created successfully": "Evento creado con éxito",
  "The event title field is required": "El campo de título de evento es obligatorio",
  "The event description field is required": "El campo de descripción de evento es obligatorio",
  "The event conditions field is required": "El campo de condiciones de evento es obligatorio",
  "Event title": "Título del evento",
  "Event date": "Fecha del evento",
  "Location": "Ubicación",
  "Start date/time availability": "Fecha/Hora Inicio disponibilidad",
  "End date/time availability": "Fecha/Hora Fin disponibilidad",
  "Event active?": "¿Evento Activo?",
  "ACTIVE": "ACTIVO",
  "INACTIVE": "INACTIVO",
  "Event conditions": "Condiciones del evento",
  "Sale verified?": "¿Venta verificada?",
  "YES": "SI",
  "NO": "NO",
  "PR/Promoters/Associates sales enabled?": "¿Habilitada venta RRPP/Promotores/Asociados?",
  "Associate the event with a tag": "Asocia el evento a un tag",
  "uploadConfirmation": "subirConfirmación",
  "dragAndDropInstruction": "arrastrarYSoltarInstrucción",
  "invalidFileTypeError": "errordDeTipoDeArchivoNoVálido",
  "dragAndDropExtended": "arrastrarYSoltarExtendido",
  "An error occurred while creating the event": "Ocurrió un error al crear el evento",
  "It is necessary to select at least one tag to create the event": "Es necesario seleccionar al menos un tag para crear el evento",
  "The ticket was successfully deleted": "El ticket se eliminó exitosamente",
  "Create new ticket": "Crear nuevo ticket",
  "Search by Ticket title...": "Buscar por titulo de Ticket...",
  "We couldn't find any tickets that match your search criteria": "No pudimos encontrar ningún ticket que coincida con sus criterios de búsqueda",
  "Are you sure you want to permanently delete this ticket?": "¿Está seguro de que desea eliminar definitivamente este ticket?",
  "Ticket management": "Administración de tickets",
  "All aspects related to application tickets can be managed from this page": "Todos los aspectos relacionados con los tickets de la aplicación se pueden gestionar desde esta página",
  "Go back to events list": "Regresar al listado de eventos",
  "Origin": "Origen",
  "Ticket types": "Tipos de tickets",
  "Category": "Categoría",
  "Direct sale": "Venta directa",
  "RRPP sales": "Venta por RRPP",
  "Sale by Producer": "Venta por Productor",
  "Sales by Producer in Backoffice": "Venta por Productor en Backoffice",
  "Exchange": "Canje",
  "Invitation": "Invitación",
  "VIP entrance": "Entrada VIP",
  "General Admission": "Entrada General",
  "Combo": "Combo",
  "Entrance": "Entrada",
  "Ticket": "Ticket",
  "Transfers": "Traslados",
  "Voucher": "Voucher",
  "Delete ticket": "Eliminar ticket",
  "Operation ID": "ID de Operación",
  "Buyer or holder": "Comprador o poseedor",
  "Email, name or surname": "Email, nombre o apellido",
  "ID, email, instagram, name, last name": "DNI, email, instagram, nombre, apellido",
  "Buyer": "Comprador",
  "DNI Buyer": "DNI Comprador",
  "DNI Holder": "DNI Poseedor",
  "Holder": "Poseedor",
  "RRPP": "RRPP",
  "Date of purchase": "Fecha de compra",
  "Sale by application": "Venta por Aplicación",
  "Sold by RRPP": "Vendidas por RRPP",
  "Tickets for sale": "Entradas en venta",
  "Total sale:": "Venta total:",
  "Sold": "Vendidas",
  "Invitations": "Invitaciones",
  "Exchanges": "Canjes",
  "Producer profit": "Ganancia de productor",
  "Sold by Producer": "Vendidas por Productor",
  "Sales by Application": "Venta por Aplicación",
  "SOLD": "VENDIDAS",
  "Referenced by RRPP": "Referenciadas por RRPP",
  "INVITATIONS": "INVITACIONES",
  "EXCHANGE": "CANJES",
  "DETAIL BY ENTRY TYPE": "DETALLE POR TIPO DE ENTRADA",
  "Statistics by event": "Estadísticas por evento",
  "Sales by producer": "Ventas por Productor",
  "Producer sale": "Venta productor",
  "Redeemed": "Canjeó",
  "Invite": "Invita",
  "Successfully edited event": "Evento editado con éxito",
  "Are you sure you want to remove the tag of all events?": "Está seguro de que desea eliminar la etiqueta de todos los eventos?",
  "Are you sure you want to remove the event tag": "Está seguro que desea eliminar la etiqueta del evento?",
  "Delete tag from all events": "Eliminar tag de todos los eventos",
  "Download list of tickets": "Descargar listado de tickets",
  "Create ticket type": "Crear tipo de ticket",
  "Update ticket list": "Actualizar listado de tickets",
  "List of Events": "Listado de Eventos",
  "Tickets to unregistered people": "Tickets a personas no registradas",
  "View QR": "Ver QR",
  "Transfer Ticket": "Transferir Ticket",
  "Event date:": "Fecha del Evento:",
  "Available until": "Disponible hasta",
  "Loaded Excel": "Cargar Excel",
  "Registered": "Registrado",
  "Not Registered": "No registrado",
  "Complete the fields below to create a new ticket": "Complete los campos a continuación para crear un nuevo ticket",
  "Go back to tickets list": "Regresar al listado de Tickets",
  "EARRING": "PENDIENTE",
  "USED": "UTILIZADO",
  "Add image": "Agregar Imagen",
  "Tickets created for unregistered users": "Tickets creados para usuarios no registrados",
  "Tickets created for registered users": "Tickets creados para usuarios registrados",
  "No tickets found.": "No se encontraron entradas.",
  "Search user by ID": "Buscar usuario por DNI",
  "Enter the user's ID": "Ingrese el DNI del usuario",
  "Clean": "Limpiar",
  "Ticket ID": "TICKET ID",
  "Ticket Type": "Tipo de ticket",
  "The tickets were saved successfully": "Los tickets fueron guardados exitosamente",
  "The ticket has been deleted successfully": "El ticket ha sido eliminado exitosamente",
  "Create Ticket": "Crear Ticket",
  "Upload excel": "Cargar excel",
  "Download excel template": "Descargar plantilla excel",
  "We couldn't find any invitations that match your search criteria": "No pudimos encontrar ninguna invitación que coincida con sus criterios de búsqueda",
  "Please complete the ticket type and quantity fields.": "Por favor completa los campos de tipo de tickets y cantidad.",
  "Guest ID": "DNI deL invitado",
  "Created By": "Creado por",
  "First Name": "Nombre",
  "Last Name": "Apellido",
  "Instagram": "Instagram",
  "Document": "Documento",
  "User Information":"Información del Usuario",
  "ACCEPT": "ACEPTAR",
  "PROVISIONAL": "PROVISORIO",
  "UNGENERATED": "SIN GENERAR",
  "An error occurred while saving tickets.": "Ocurrio un error al guardar los tickets.",
  "The event were saved successfully": "El evento se guardó existoamente",
  "An error occurred while saving the event": "Se produjo un error al guardar el evento",
  "Add Location": "Agregar Ubicación",
  "Error loading Google Maps API": "Error al cargar la API de Google Maps",
  "Search for a place": "Buscar un lugar",
  "Loading...": "Cargando...",
  "You must enter a name for the location": "Debe ingresar un nombre para la ubicación",
  "You must enter an address for the location": "Debe ingresar una dirección para la ubicación",
  "No location found": "No se encontraron lugares",
  "No places found": "No se encontró ninguna ubicación",
  "Select the tickets to sell": "Seleccione los tickets a vender",
  "Change event": "Cambiar evento",
  "Total to pay:": "Total a pagar:",
  "Scan partner": "Escanear socio",
  "Enter ID or Passport": "Ingresar DNI o Pasaporte",
  "Ticket sold out": "Tickets agotados",
  "Select the tickets to purchase:": "Seleccione los tickets a vender:",
  "Summary": "Resumen",
  "Required field": "Campo requerido",
  "The event date cannot be equal to or before the current date": "La fecha del evento no puede ser igual o anterior a la fecha actual",
  "Event date required": "Se requiere la fecha del evento",
  "Availability start date/time is required": "Se requiere la fecha/hora de inicio de disponibilidad",
  "Availability end date/time required": "Se requiere fecha/hora de finalización de disponibilidad",
  "The availability start date/time cannot be equal to or later than the event date/time or the event availability end date": "La fecha/hora de inicio de disponibilidad no puede ser igual o posterior a la fecha/hora del evento ni la fecha de fin de disponibilidad del evento",
  "Availability end date/time cannot be earlier than or equal to event date/time": "La fecha/hora de finalización de disponibilidad no puede ser anterior o igual a la fecha/hora del evento",
  "Finalize Sale": "Finalizar venta",
  "Change User": "Cambiar usuario",
  "Aim the QR reader": "Apunta el lector QR",
  "Confirm the deletion of the last ticket?": "¿Confirma la eliminación del último ticket?",
  "Unregistered user": "Usuario no registrado",
  "Registered user": "Usuario registrado",
  "Cancel sale": "Cancelar venta",
  "Sell ticket": "Vender ticket",
  "No places available": "No se encontraron lugares disponibles",
  "View invitations": "Ver invitaciones",
  "QR Code": "Código QR",
  "PRINT TICKETS": "IMPRIMIR TICKETS",
  "The element with id='qrcode-container' is not an SVG.": "El elemento con id='qrcode-container' no es un SVG.",
  "Your Tickets:": "Tus Entradas:",
  "Your tickets:": "Tus tickets:",
  "CLOSE": "CERRAR",
  "Transfer the Ticket": "Transferir el Ticket",
  "Enter the destination ID": "Ingresá el DNI destino",
  "Enter the ID:": "Ingresá el DNI:",
  "CANCEL": "CANCELAR",
  "TRANSFER": "TRANSFERIR",
  "Does not specify entry detail": "No especifica detalle de entrada",
  "You must enter a valid DNI": "Debe introducir un DNI válido",
  "You must complete the field": "Debe completar el campo",
  "The ticket has been transferred successfully": "El ticket ha sido transferido exitosamente",
  "An error occurred while transferring the ticket": "Ocurrió un error al transferir el ticket",
  "A user with the entered ID was not found.": "No se encontró un usuario con el DNI ingresado.",
  "The destination ID does not exist": "El DNI destino no existe",
  "The event cannot be deleted as it contains tickets": "El evento no puede eliminarse ya que contiene tickets",
  "An error occurred while trying to delete the ticket.": "Ocurrió un error al intentar borrar el ticket.",
  "No tickets have been registered for this event yet": "Aún no se han registrado tickets en este evento",
  "Unable to access at this time": "No se puede acceder en este momento",
  "Delete ticket to be generated": "Eliminar ticket a generar",
  "An error occurred while deleting the ticket": "A ocurrido un error al eliminar el ticket",
  "You can't transfer the ticket to the same document": "No puedes transferir el ticket al mismo DNI",
  "You must enter a different ID": "Debes ingresar un DNI diferente",
  "Provisional ticket": "Ticket provisorio",
  "Provisional tickets": "Tickets provisorios",
  "Search by ID": "Buscar por DNI",
  "We have not found invitations.": "No hemos encontrado invitaciones.",
  "Name is required": "Se requiere el nombre",
  "Location is required": "Se requiere ubicación",
  "An error occurred while trying to save tickets.": "Se produjo un error al intentar guardar los tickets.",
  "Only alphanumeric characters are allowed":"Sólo están permitidos caracteres alfanuméricos",
  "Activate event": "Activar evento",
  "Deactivate event": "Desactivar evento",
  "The event status has been successfully changed": "El status del evento ha sido modificado exitosamente",
  "Error changing the event status": "Error al cambiar el status del evento",
  "No image uploaded": "No se ha cargado ninguna imagen",
  "file-uploaded": "Archivo cargado",
  "Invalid state value": "Valor de estado inválido",
  "Are you sure you want to delete this ticket?": "¿Estás seguro de que quieres eliminar este ticket?",
  "The ticket has been deleted.": "El ticket ha sido eliminado.",
  "Search user": "Buscar usuario",
  "Ticket quantity is required": "La cantidad de tickets es requerida",
  "The end date of availability cannot be after the event date": "La fecha de finalización de la disponibilidad no puede ser posterior a la fecha del evento",
  "An error occurred while trying to create the ticket": "Se produjo un error al intentar crear el ticket",
  "The end date of availability must be after the event date and the start date of availability": "La fecha final de disponibilidad debe ser posterior a la fecha del evento y a la fecha de inicio de disponibilidad",
  "The availability start date/time cannot be the same as the event date": "La fecha/hora de inicio de disponibilidad no debe ser igual a la fecha del evento",
  "Only positive numbers are allowed": "Solo se permiten números positivos",
  "An error occurred with the user's ID and the type of ticket selected": "Se produjo un error con el DNI del usuario y el tipo de ticket seleccionado",
  "You must select the ticket type": "Debe seleccionar el tipo de ticket",
  "It is necessary to add the user's ID": "Es necesario agregar el DNI del usuario",
  "You must search for the user to create the ticket": "Debes buscar el usuario para crear el ticket",
  "Click on this button to search for the ID": "Hacer click en este botón para realizar la búsqueda del DNI",
  "Valid date and time is required": "Fecha y hora válida es requerida",
  "You must download the Excel template and complete all the fields.": "Debes descargar la plantilla de Excel y completar todos los campos.",
  "Complete the Excel template with all the required data and then upload the file.": "Completar la plantilla Excel con todos los datos requeridos y luego cargar el archivo.",
  "It is necessary to complete the entire form": "Es necesario completar todo el formulario",
  "An error occurred while trying to load the template": "Se produjo un error al intentar cargar la plantilla",
  "It is necessary to complete the ticket type to upload user data": "Es necesario completar el tipo de ticket para cargar los datos de los usuarios",
  "Only numbers greater than 0 are allowed": "Sólo se permiten números mayores que 0",
  "Only alphanumeric characters and accents are allowed": "Sólo se permiten caracteres alfanuméricos y acentos",
  "Only alphanumeric and special characters are allowed": "Sólo se permiten caracteres alfanuméricos y especiales",
  "The customer exceeds the number of tickets allowed per person": "El cliente excede la cantidad de tickets permitidos por persona",
  "The client with ID": "El cliente con DNI",
  "exceeds the number of tickets allowed": "excede la cantidad de tickets permitidos",
  "The selected ticket type has no tickets available": "El tipo de ticket seleccionado no posee tickets disponibles",
  "Show only erroneous tickets": "Mostrar solo tickets erroneos",
  "The quantity item only accepts positive numbers other than zero": "El item cantidad sólo acepta números positivos distintos de cero",
  "The DNI does not meet the requirements: alphanumeric - up to 10 characters": "El DNI no cumple con los requisitos: alfanumérico - hasta 10 caracteres",
  "There are tickets with errors": "Existen tickets con error",
  "Do you want to process equally ignoring erroneous tickets?": "¿Desea procesar igualmente ignorando los tickets erróneos?",
  "Please note:": "Por favor tenga en cuenta:",
  "Tickets with errors are mentioned in the detail list: 'Error' column.": "Los tickets que presentan error están mencionados en el listado de detalle: columna 'Error'.",
  "Tickets that have at least one error reported will not be created.": "Los tickets que tengan informado al menos un error no serán creados.",
  "If you have uploaded tickets through Excel without entering a quantity, the ticket will be assigned with a default quantity: 1.": "Si ha realizado la carga de tickets por excel sin colocar cantidad, el ticket se asignará con cantidad por defecto: 1.",
  "An error occurred while trying to create tickets": "Se produjo un error al intentar crear los tickets",
  "The number of tickets that have been attempted to be saved is zero.": "La cantidad de tickets que se ha intentado guardar es cero.",
  "Select valid date/time": "Seleccione fecha/hora válida",
  "The date must be between the years 2000 and 2100": "La fecha debe estar entre los años 2000 y 2100",
  "If you have uploaded tickets through Excel:": "Si has subido tickets a través de Excel:",
  "If the quantity is omitted, the default value will be assigned: 1.": "Si se omite la cantidad, se asignará el valor predeterminado: 1.",
  "If the ID presents any symbol, it will be omitted and only the alphanumeric characters will be taken into account.": "Si el DNI presenta algún símbolo, se omitirá y sólo se tendrán en cuenta los caracteres alfanuméricos.",
  "You can return to the list and review your tickets": "Podes regresar al listado y revisar tus tickets",
  "Successfully created place": "Ubicación creada con éxito",
  "Error creating place":"Error al crear ubicación",
  "An error occurred while creating the event, please inform the service provider.": "Ocurrió un error al crear el evento, informe al proveedor de servicio.",
  "There are only ${availableTickets} tickets available of type ${ticketType}, and you are trying to generate ${requestedTickets} tickets.": "Solo quedan ${availableTickets} tickets disponibles de tipo ${ticketType}, y se están intentando generar ${requestedTickets} tickets.",
  "You cannot generate these tickets. They all have errors.": "No puedes generar estos tickets. Todos presentan error.",
  "Direct Sales": "Venta Directa",
  "There are no tickets available of selected type of ticket" : "No hay tickets disponibles para el tipo de ticket seleccionado",
  "Copy tickets type? Remember to check their availability date":"¿Copiar Tipo de Tickets? Recuerde verificar la fecha de disponibilidad de los mismos",
  "You must search on the map": "Debes realizar la búsqueda en el mapa",
  "An error occurred while searching for the location": "Ocurrió un error al buscar la ubicación",
  "No user found with this DNI": "No se ha encontrado ningún usuario con este DNI",
  "An error occurred while searching for the user": "Se produjo un error al buscar el usuario",
  "An error occurred while trying to download the list.": "Ocurrió un error al intentar descargar el listado.",
  "No records of statistical data were found in": "No se encontraron registros de datos estadísticos en",
  "Direct Sales by PR": "Ventas Directas por RRPP",
  "Required Location": "Ubicación requerida",
  "Required Image": "Imagen requerida del evento",

  // Statistics
  "Statistics management": "Administración de estadísticas",
  "All aspects related to the app's statistics can be managed from this page": "Todos los aspectos relacionados con las estadísticas de la app se pueden gestionar desde esta página",
  "Date from": "Fecha desde",
  "Date until": "Fecha hasta",
  "Invitations only": "Solo invitaciones",
  "By Client": "Por Cliente",
  "By Event": "Por Evento",
  "By RRPP": "Por RRPP",
  "Producer": "Productor",
  "Surname and Name": "Apellido y Nombre",
  "Download list of statistics": "Descargar listado de estadísticas",
  "User": "Usuario",
  "State": "Estado",
  "es": "es",
  "Total": "Total",
  "All locations": "Todas las ubicaciones",
  "All the states": "Todos los estados",
  "Arches of Córdoba": "Arcos de Córdoba",
  "Without using": "Sin usar",
  "Used": "Usado",
  "Quantity": "Cantidad",
  "Event": "Evento",
  "Ticket type": "Tipo de ticket",
  "DNI": "DNI",
  "All the locations": "Todas las ubicaciones",
  "DNI, email, first or last name": "DNI, email, nombre o apellido",
  "No statistics were found with the information provided. Please add one": "No se encontraron estadísticas con la informacion proporcionada. Por favor, agregue una.",
  "An error occurred while listing statistics by Event.": "Ha ocurrido un error al listar las estadisticas por Evento.",
  "An error occurred while listing statistics by Client.": "Ha ocurrido un error al listar las estadisticas por Cliente.",
  "An error occurred while listing statistics by RRPP.": "Ha ocurrido un error al listar las estadisticas por RRPP.",
  "Bulk actions": "Acciones masivas",
  "Resend verification email": "Reenviar correo electrónico de verificación",
  "Bulk edit accounts": "Edición masiva de cuentas",
  "Close selected accounts": "Cerrar cuentas seleccionadas",
  "We couldn't find any result matching your search criteria": "No pudimos encontrar ningún resultado que coincida con sus criterios de búsqueda",
  "Start date cannot be after end date": "Fecha de inicio no puede ser mayor a la fecha de fin",
  "Invalid date range":"Rango de fecha inválido",
  "Add new location": "Agregar nueva ubicación",
  "Select location": "Selecioná la ubicación",
  "Only alphanumeric characters and the following symbols are allowed: $ _ . - : ; # @":"Solo se permiten caracteres alfanuméricos y los siguientes símbolos: $ _ . - : ; # @",
  "Detail of": "Detalle de",
  "Please enter a valid date": "Por favor inserte una fecha válida",
  "The from date must be before the to date": "La fecha desde debe ser anterior a la fecha hasta",
  "The to date must be after the from date and cannot be in the future": "La fecha hasta debe ser posterior a la fecha desde y no puede ser futura",

  //Notifications
  "Notification management": "Administración de notificaciones",
  "All aspects related to app notifications can be managed from this page": "Todos los aspectos relacionados con las notificaciones de la aplicación se pueden gestionar desde esta página",
  "Search by title or description": "Buscar por título o descripción",
  "Title": "Título",
  "Image": "Imagen",
  "Execution date": "Fecha de ejecución",
  "Create new notification": "Crear nueva notificación",
  "Users notified": "Usuarios notificados",
  "The notification has been successfully deleted": "La notificación se ha eliminado correctamente.",
  "Error deleting the notification": "Error al borrar la notificación",
  "No notifications were found with the parameters entered": "No se encontraron notificaciones con los parámetros ingresados",
  "Edit notification": "Editar notificación",
  "Delete notification": "Eliminar notificación",
  "Are you sure you want to permanently delete this notification?": "¿Está seguro de que desea eliminar permanentemente esta notificación?",
  "Edit": "Editar",
  "Title of notification": "Título de la notificación",
  "Body of notification": "Cuerpo de la notificación",
  "Date of notification": "Fecha de notificación",
  "Select tags": "Seleccionar etiquetas",
  "Send now": "Enviar ahora",
  "Reset": "Reiniciar",
  "Save": "Guardar",
  "SENT": "ENVIADA",
  "SCHEDULED":"AGENDADA",
  "ERROR": "ERRÓNEA",
  "Go back to notifications list": "Regresar al listado de notificaciones",
  "The title of the notification is required": "El título de la notificación es obligatorio",
  "The notification body is required": "El cuerpo de la notificación es obligatorio",
  "Date to be notified": "Fecha a notificar",
  "If no tag is selected, the notification will be sent to all users.": "Si no se selecciona ningun tag se enviara la notificación a todos los usuarios.",
  "Complete all fields to edit the notification": "Complete todos los campos para editar la notificación",
  "Search": "Buscar",
  "Notification deleted successfully": "Notificacion eliminada correctamente",
  "Notification not found": "Notificacion no encontrada",
  "Title of notification is required": "Se requiere el título de la notificación",
  "The body of notification is required": "El cuerpo de la notificación es obligatorio.",
  "Body of notification is required": "Se requiere el cuerpo de la notificación",
  "If you do not select any tags, the notification will be sent to all users.": "Si no selecciona ningún tag se enviará la notificación a todos los usuarios.",
  "Create notification": "Crear notificación",
  "Fill in the fields to create a new notification": "Complete los campos para crear una nueva notificación",
  "The notification was created correctly.": "La notificación se creó correctamente.",
  "The notification has been edited successfully.": "La notificación ha sido editada correctamente.",
  "We saved the notification but it could not be sent.": "Guardamos la notificación pero no se pudo enviar.",
  "The title of notification cannot exceed 150 characters":"El título de la notificación no puede exceder los 150 caracteres",
  "The body of notification cannot exceed 500 characters":"El cuerpo de la notificación no puede exceder los 500 caracteres",
  "The selected date and time cannot be earlier than the current one": "La fecha y hora seleccionadas no pueden ser anteriores a la actual",
  "Select valid date and time": "Seleccione fecha y hora válida",
  "Reload notifications": "Recargar notificaciones",
  "The execution date and time must be later than the current date and time": "La fecha y hora de ejecución deben ser posteriores a la fecha y hora actuales",
  // Users
  "Users management": "Administración de usuarios",
  "All aspects related to application users can be managed from this page": "Todos los aspectos relacionados con los usuarios de la aplicación se pueden gestionar desde esta página",
  "Create new user": "Crear nuevo usuario",
  "Download list of users": "Descargar listado de usuarios",
  "The user has been deleted successfully": "El usuario se ha eliminado exitosamente",
  "The user has been copied successfully": "El usuario se ha copiado exitosamente",
  "Search by user, name, lastname, email, telephone, id...": "Buscar por usuario, nombre, apellido, correo electrónico, telefono, dni...",
  "We couldn't find any user matching your search criteria": "No pudimos encontrar ningún usuario que coincida con sus criterios de búsqueda",
  "Edit user": "Editar usuario",
  "Delete user": "Eliminar usuario",
  "Do you really want to delete this user?": "¿Realmente deseas eliminar este usuario?",
  "Filter by role": "Filtrar por roles",
  "Filter by gender": "Filtrar por género",
  "Filter by tags": "Filtrar por etiquetas",
  "Select role": "Seleccione roles",
  "Birthday month": "Mes de cumpleaños",
  "Clear": "Limpiar",
  "Verification date": "Fecha de verificación",
  "Name and Lastname": "Apellido y Nombre",
  "ID": "DNI",
  "Date of birth": "Fecha de nacimiento",
  "Telephone": 'Télefono',
  "Role": "Rol",
  "Last movements": "Últimos movimientos",
  "Load points": "Cargar puntos",
  "Notify user": "Notificar usuario",
  "Verify user": "Verificar usuario",
  "Unverify user": "Anular verificación",
  "Deactivate user": "Desactivar usuario",
  "Activate user": "Activar usuario",
  "Are you sure you want to activate the user": "Está seguro que desea activar el usuario",
  "Are you sure you want to deactivate the user": "Está seguro que desea desactivar el usuario",
  "Inactive user":"Usuario inactivo",
  "Active user":"Usuario activo",
  'User deactivated successfully': "Usuario desactivado exitosamente",
  'User activated successfully': "Usuario activado exitosamente",
  "Not available":"No disponible",
  "Transfer": "Transferir",
  "Cancel": "Cancelar",
  "Amount": "Monto",
  "Enter the amount": "Ingresá el monto",
  "Transfer loyalty points": "Transferir puntos de fidelización",
  "Please enter a positive amount greater than zero": "Por favor, ingrese un monto positivo mayor a cero",
  "They transferred": "Se transfirieron",
  "points correctly": "puntos correctamente",
  "You must enter only positive numbers greater than zero": "Debe ingresar solo números positivos mayores a cero",
  "Error transferring points": "Error al transferir los puntos",
  "Delete tag from all users": "Eliminar etiqueta de todos los usuarios",
  "The tag was successfully removed from the user": "La etiqueta se eliminó exitosamente del usuario",
  "The tag was successfully removed of all users.": "La etiqueta se eliminó exitosamente de todos los usuarios.",
  "Are you sure you want to remove the user tag":"¿Está seguro que desea eliminar la etiqueta del usuario?",
  "Are you sure you want to remove the tag of all users?": "¿Está seguro de que desea eliminar la etiqueta de todos los usuarios?",
  "Are you sure you want to remove the tag?": "¿Está seguro que desea eliminar la etiqueta?",
  "The tag could not be deleted": "La etiqueta no pudo ser eliminado",
  "An error occurred while deleting the user tag": "Ocurrió un error al eliminar la etiqueta del usuario",
  "An error occurred while deleting the tag for all users.": "Ocurrió un error al eliminar la etiqueta de todos los usuarios.",
  "Assign role to": "Asignar rol a",
  "Must be a valid email": "Debe ser un correo electrónico válido",
  "The role field is required": "El campo de rol es obligatorio",
  "At least one role is required": "Se requiere al menos un rol",
  "Email": "Correo electrónico",
  "Select a role": "Selecciona un rol",
  "Select user redeemers rol": "Selecciona el rol de redimidores de usuario",
  "Select redeemers rol": "Selecciona el rol de redimidores",
  "The user has been successfully edited": "El usuario se ha editado exitosamente",
  "Send notification to": "Enviar notificación a",
  "The title field is mandatory": "El campo de título es obligatorio",
  "The description field is mandatory": "El campo de descripción es obligatorio",
  "Send": "Enviar",
  "Message sent successfully": "Mensaje enviado exitosamente",
  "Notification sent successfully":"La notificación ha sido enviada correctamente",
  "Are you sure you want to verify the user ": "¿Esta seguro que desea verificar al usuario ",
  "Are you sure you want to unverify the user ": "¿Esta seguro que desea anular la verificación del usuario ",
  "Notification sent":"Notificación enviada",
  "The user was successfully verified": "El usuario se verificó exitosamente",
  "The user was successfully unverified": "Se anuló la verificación del usuario exitosamente",
  "Description": "Descripción",
  "of": "de",
  "Your points": "Tus puntos",
  "Date": "Fecha",
  "Points": "Puntos",
  "Rows per page": "Filas por página",
  "Close": "Cerrar",
  "Sales Referenced by PR": "Ventas Referenciadas por RRPP",
  "Go back to event statistics": "Regresar a estadísticas de evento",
  "Search by first or last name...": "Buscar por nombre o apellido...",
  "We couldn't find any statistics data matching your search criteria": "No pudimos encontrar ningún dato de estadística que coincida con sus criterios de búsqueda",
  "Referenced sale": "Venta referenciada",
  "See detail": "Ver detalle",
  "Direct Sales by RRPP":"Ventas Directas por RRPP",
  "User has been updated successfully": "El usuario se ha actualizado exitosamente",
  "The user name field is mandatory": "El campo de nombre de usuario es obligatorio",
  "The user last name field is mandatory": "El campo de apellido de usuario es obligatorio",
  "The user DNI is mandatory": "El campo de DNI de usuario es obligatorio",
  "The birth date field is mandatory": "El campo de fecha de nacimiento es obligatorio",
  "The gender field is mandatory": "El campo de género es obligatorio",
  "The instagram profile field is mandatory": "El campo de perfil de instagram es obligatorio",
  'Instagram profile is too long': "El nombre de instagram es muy largo",
  'Only alphanumeric characters and underscores are allowed': "Solo se aceptan caracteres alfanuméricos",
  "Fill in the fields below to update the user item": "Complete los campos a continuación para actualizar el usuario",
  "Go back to the users list": "Regresar al listado de usuarios",
  "Birth date": "Fecha de nacimiento",
  "An error occurred while sending the notification":"Ocurrió un error al enviar la notificación",
  "An error occurred while verifying the user":"Ocurrió un error al verificar usuario",
  "Deleted":"Eliminados",
  "Reload list of users":"Actualizar listado de usuarios",
  "Female" :"Femenino",
  "Male":"Masculino",
  "All":"Todos",
  "VERIFIED":"VERIFICADO",
  "UNVERIFIED":"NO VERIFICADO",
  "Day from cannot be greater than day to":"El día desde no puede ser mayor que el día hasta",
  "The day to cannot be less than the day from":"El día hasta no puede ser menor que el día desde",
  "Tag deleted successfully from all users":"Etiqueta eliminada exitosamente de todos los usuarios",
  "An error occurred while deleting the tag":"Un error ha ocurrido mientras se eliminaba la etiqueta",
  "Tag deleted successfully from user": "Etiqueta eliminada exitosamente del usuario",
  "An error occurred while adding the tag": "Un error ha ocurrido mientras se agregaba la etiqueta",
  "An error occurred while editing the user role": "Un error ha ocurrido mientras se editaba el rol del usuario",
  "There is an issue with the DNI field. It cannot be null or undefined.":"Hubo un problema al verificar usuario, el DNI no puede ser nulo",
  "STATUS":"ESTADO",
  "No record found":"No se encontró ningún registro",
  " Please review all fields.": "Se produjo un error al intentar editar el usuario. Por favor, revisa todos los campos.",
  "An error occurred while trying to edit the user. Please review all fields.": "Se produjo un error al intentar editar el usuario. Por favor, revisa todos los campos.",
  "An error occurred while trying to edit the user": "Se produjo un error al intentar editar el usuario",
  "The user DNI must be at least 6 characters long": "El DNI del usuario debe tener al menos 6 caracteres",
  "The user ID must be a maximum of 10 characters": "El DNI del usuario debe tener como máximo 10 caracteres",
  "The user name field cannot exceed 255 characters": "El nombre de usuario no puede exceder los 255 caracteres",
  "Date of birth is invalid": "La fecha de nacimiento es inválida",
  "The date is invalid. Expected format: DD/MM/YYYY": "La fecha es inválida. Formato esperado: DD/MM/AAAA",
  "An error occurred while trying to edit the user.": "Se produjo un error al intentar editar el usuario.",
  "Please select a valid birth date": "Por favor, seleccione una fecha de nacimiento válida",
  "Please enter a date in the format dd/MM/yyyy": "Por favor, ingresá una fecha en el formato DD/MM/AAAA",
  "Birth date must be after 01/01/1900": "La fecha de nacimiento debe ser posterior al 01/01/1900",
  "It is not possible to edit the user's birthday. Associated tags": "No es posible editar la fecha de cumpleaños del usuario. Tags asociados",

  // News
  "The news has been successfully deleted": "La novedad se eliminó exitosamente",
  "We couldn't find any news matching your search criteria": "No pudimos encontrar ninguna novedad que coincida con sus criterios de búsqueda",
  "Diffusion": "Difusión",
  "No image": "Sin imagen", 
  "Create news": "Crear novedad",
  "Create News": "Crear Novedad",
  "Edit News": "Editar Novedad",
  "Fill in the fields below to create a new news item": "Complete los campos a continuación para crear una novedad",
  "Edit news": "Editar novedad",
  "Fill in the fields below to update the news item": "Complete los campos a continuación para actualizar la novedad",
  "Create new news": "Crear nueva novedad",
  "Delete news": "Eliminar novedad",
  "Do you really want to delete this news?": "¿Realmente deseas eliminar esta novedad?",
  "News Management": "Administración de novedades",
  "All aspects related to the application news can be managed from this page": "Aquí puedes administrar todas las novedades de la aplicación",
  "News has been updated successfully": "La novedad se actualizó exitosamente",
  "News has been created successfully": "La novedad se creó exitosamente",
  "Publication date": "Fecha de publicación",
  "Public": "Público",
  "Private": "Privado",
  "Notify": "Notificar",
  "Return": "Volver",
  "Go back to the news list": "Regresar al listado de novedades",
  "Drag & drop here the image you want to upload": "Arrastre y suelte la imagen que desea subir aquí...",
  "You can not upload this type of file": "No puedes subir este tipo de archivo",
  "Drag and drop here...": "Arrastre y suelte aquí...",
  "You uploaded": "Has subido",
  "file": "archivo",
  "The news title field is mandatory": "El campo de título de la novedad es obligatorio",
  "The news description field is mandatory": "El campo de descripción de la novedad es obligatorio",
  "The news notify field is mandatory": "El campo de notificar novedad es obligatorio",
  "The news date field is mandatory": "El campo de fecha de la novedad es obligatorio",
  "The news date field must be greater than the current date": "El campo de fecha de la novedad debe ser mayor que la fecha actual",
  "The news diffusion field is mandatory": "El campo de difusión de la novedad es obligatorio",
  "Search by title...": "Buscar por título...",
  "Please select a valid image file (JPEG, PNG).": "Por favor, seleccione un archivo de imagen válido (JPEG, PNG).",

  // Roles
  "Role management": "Administración de roles",
  "Edit role": "Editar rol",
  "Fill in the fields below to edit a role": "Complete los campos para editar un rol",
  "Go back to the roles list": "Regresar al listado de roles",
  "Name of role":"Nombre del rol",
  "Description of role": "Descripción del rol",
  "Add users": "Agregar usuarios",
  "Type at least 3 characters to search":"Escriba al menos 3 letras para buscar",
  "Select a user": "Seleccione un usuario",
  "No results found":"No se encontraron resultados",   
  "No users found":"No se encontraron usuarios",
  "The name of role is required":"El nombre del rol es obligatorio",
  "The description of role is required":"La descripción  del rol es obligatoria",
  "Permissions":"Permisos",
  "Locations":"Ubicaciones",
  "Add permissions":"Agregar permisos",
  "Add locations":"Agregar ubicaciones",
  "The role has been successfully edited":"El rol fue editado exitosamente",
  "Could not edit role":"No se puedo editar el rol",
  "The role has been created successfully": "El rol se ha creado exitosamente",
  "The role could not be created": "El rol no pudo ser creado",
  "A role with the same name already exists": "Ya hay un rol con el mismo nombre",
  "The role name field is mandatory": "El campo de nombre de rol es obligatorio",
  "The role description field is mandatory": "El campo de descripción de rol es obligatorio",
  "The user field is mandatory": "El campo de usuario es obligatorio",
  "The users field is mandatory": "El campo de usuario es obligatorio",
  "The permissions field is mandatory": "El campo de permisos es obligatorio",
  "The locations field is mandatory": "El campo de ubicaciones es obligatorio",
  "No users": "Sin usuarios",
  "Add location": "Agregar ubicación",
  "Create role": "Crear rol",
  "Create a new role": "Crear un nuevo rol",
  "Fill in the fields below to create a new role": "Complete los campos a continuación para crear un nuevo rol",
  "Go back to the role list": "Regresar al listado de roles",
  "Edit rol": "Editar Rol",
  "Delete rol": "Eliminar Rol",
  "Add user": "Agregar Usuario",
  "User has been removed from the role": "Se ha eliminado el usuario del rol",
  "An error occurred while removing the user from the role": "Ha ocurrido un error al eliminar el usuario del rol",
  "Add user to rol": "Agregar usuario al rol",
  "Select users": "Seleccionar usuarios",
  "Selected users": "Usuarios seleccionados",
  "Are you sure you want to delete this role?": "¿Está seguro de que desea eliminar este rol?",
  "Users with selected role": "Usuarios con el Rol seleccionado",
  "Permit location": "Ubicación de los permisos",
  "All aspects related to application roles can be managed from this page": "Todos los aspectos relacionados con los roles de la aplicación se pueden gestionar desde esta página",
  "Create new role": "Crear nuevo rol",
  "Search by role name": "Buscar por nombre de rol",
  "We couldn't find any roles matching your search criteria.": "No pudimos encontrar ningún rol que coincida con sus criterios de búsqueda.",
  "Users have been added to the role": "El usuario ha sido agregado al rol con éxito",
  "An error occurred while adding users to the role": "Ha ocurrido un error al intentar agregar un usuario al rol",
  "The role was successfully deleted": "El rol ha sido borrado exitosamente",
  "The role name must be at most 255 characters": "El nombre del rol debe tener como máximo 255 caracteres",
  "The role description must be at most 255 characters": "La descripción del rol debe tener como máximo 255 caracteres",
  
  // Tags
  "Tags management": "Administración de tags",
  "All aspects related to application tags can be managed from this page": "Todos los aspectos relacionados con las tags de la aplicación se pueden gestionar desde esta página",
  "We couldn't find any tag matching your search criteria": "No pudimos encontrar ningún tag que coincida con sus criterios de búsqueda",
  "Create new tag": "Crear nuevo tag",
  "Create tag": "Crear tag",
  "Edit tag": "Editar tag",
  "Delete tag": "Eliminar tag",
  "Search by tag": "Buscar por tag",
  'Continue': 'Continuar',
  "Color":"Color",
  "Is visible?": "¿Es visible?",
  "Is visible": "Es visible",
  "Complete the fields below to create a new tag": "Complete los campos a continuación para crear un nuevo tag",
  "Complete the fields below to edit a tag": "Complete los campos a continuación para editar un tag",
  "Go back to the tags list": "Regresar al listado de tags",
  "The tag name is required": "El nombre del tag es obligatorio",
  'Preview': 'Vista previa',
  "Tag created successfully": "Tag creado con éxito",
  "Tag edited successfully": "Tag editado con éxito",
  "An error occurred while creating the tag":"Ocurrió un error al crear el tag",
  "An error occurred while editing the tag":"Ocurrió un error al editar el tag",
  "The tag has been deleted successfully": "El tag se ha eliminado exitosamente",
  "Do you really want to delete this tag":"¿Realmente deseas eliminar este tag?",
  "The user has no points assigned.": "El usuario no tiene puntos asignado.",
  "Error loading users and points": "Error al cargar usuarios y puntos",
  "Invalid date": "Fecha inválida",
  "We couldn't find any users matching your search criteria": "No pudimos encontrar ningún usuario que coincida con sus criterios de búsqueda",
  "Add tags to selected users": "Agregar tags a los usuarios seleccionados",
  "Add": "Agregar",
  "Add tags": "Agregar etiquetas",
  "Add new tag": "Agregar nueva etiqueta",
  "Tag name": "Nombre de la etiqueta",
  "Tag color": "Color de la etiqueta",
  "Visible": "Visible",
  "Tag name cannot be empty": "El nombre de la etiqueta no puede estar vacío",
  "The tag already exists": "La etiqueta ya existe",
  "The new tag has been created successfully!": "¡Nueva etiqueta creada exitosamente!",
  "Please select at least one tag": "Por favor, seleccione al menos una etiqueta",
  "Please select at least one user": "Por favor, seleccione al menos un usuario",
  "The assignment of users to Tags has been completed!": "¡Se ha completado la asignación etiquetas a usuarios!",
  "Verified": "Verificada",
  "Not verified": "No verificada",
  "Unknown": "Desconocido",
  "User was successfully deleted": "El usuario se eliminó exitosamente",
  "There is no tag to display": "No hay etiquetas para mostrar",
  "No tags found": " No se encontraron tags",
  "The tag name must be at most 20 characters":"El nombre de la etiqueta debe tener como máximo 20 caracteres",
  "You can only select up to 5 tags": "Solo puedes seleccionar hasta 5 etiquetas",
  "tags":"etiquetas",
  "The tag name is required and must be at most 20 characters": "El nombre del tag es obligatorio y debe tener como máximo 20 caracteres",

  //Ticket Types
  "Ticket types management": "Administración de tipo de tickets",
  "All aspects related to application tickets type can be managed from this page":"Todos los aspectos relacionados con el tipo de tickets de la aplicación se pueden gestionar desde esta página",
  "Create new ticket type":"Crear nuevo tipo de ticket",
  "Copy ticket type": "Copiar tipo de ticket",
  "Edit ticket type": "Editar tipo de ticket",
  "Delete ticket type": "Eliminar tipo de ticket",
  "The ticket type has been deleted successfully": "El tipo de ticket se ha eliminado exitosamente",
  "The ticket type has been edited successfully": "El tipo de ticket se ha editado exitosamente",
  "The ticket type has been successfully copied":"El tipo de ticket se ha copiado exitosamente",
  "Do you really want to delete the ticket type ":"¿Desea eliminar el tipo de ticket ",
  "We couldn't find any ticket type matching your search criteria": "No pudimos encontrar ningún tipo de ticket que coincida con sus criterios de búsqueda",
  "Search by ticket name": "Buscar por nombre de ticket",
  "Type":"Tipo",
  "Price":"Precio",
  "Available": "Disponible",
  "Total batch":"Lote total",
  "Available from":"Disponible desde",
  "Available to":"Disponible hasta",
  "Updated by":"Actualizado por",
  "Cancel verification": "Cancelar verificación",
  "Ticket type has been created successfully": "El tipo de ticket se ha creado exitosamente",
  "Ticket type has been updated successfully": "El tipo de ticket se ha actualizado exitosamente",
  "Ticket type has been copied successfully": "El tipo de ticket se ha copiado exitosamente",
  "Name to display in the purchase": "Nombre a mostrar en la compra",
  "Name to display on the sold ticket": "Nombre a mostrar en el ticket vendido",
  "Name to display in statistics": "Nombre a mostrar en estadísticas",
  "Ticket category": "Categoría de ticket",
  "Add ticket category": "Agregar categoría de ticket",
  "Price(in ARS)": "Precio(en ARS)",
  "Price(ARS)": "Precio(ARS)",
  "$ to give to the producer": "$ a rendir al productor",
  "Ticket color": "Color del ticket",
  "Max. tickets per user": "Máx. tickets por usuario",
  "Total batch of tickets": "Lote total de tickets",
  "Available tickets": "Tickets disponibles",
  "Is it sold out?" : "¿Está agotado?",
  "Is ticket type visible in App?" : "¿Tipo de ticket visible en aplicación?",
  "Is ticket type visible at the box office?": "¿El tipo de ticket es visible en boletería?",
  "Ticket outdate date": "Fecha de vencimiento del ticket",
  "Associate the ticket type with a tag": "Asocia el tipo de ticket a un tag",
  "Tickets icon": "Icono de tickets",
  "Create Ticket Type": "Crear tipo de ticket",
  "Fill in the fields below to create a new ticket type": "Complete los campos a continuación para crear un nuevo tipo de ticket",
  "Edit Ticket Type": "Editar tipo de ticket",
  "Fill in the fields below to update the ticket type": "Complete los campos a continuación para actualizar el tipo de ticket",
  "Copy Ticket Type": "Copiar tipo de ticket",
  "Fill in the fields below to copy the ticket type": "Complete los campos a continuación para copiar el tipo de ticket",
  "Go back to the ticket types list": "Regresar al listado de tipos de tickets",
  "The start date must be later than the event availability start date": "La fecha de inicio debe ser posterior que la fecha de inicio de disponibilidad del evento",
  "The outdate date must be greater than the current date": "La fecha de vencimiento debe ser mayor que la fecha actual",
  "Full name": "Nombre y apellido",
  "Not registered": "No registrados",
  "Ticket type is required": "Tipo de ticket es requerido",
  "The quantity must be at least 1": "La cantidad debe ser al menos de 1",
  "User ID is required": "DNI de usuario es requerido",
  "The end date must be greater than or equal to the start date": "La fecha de finalización debe ser mayor o igual a la fecha de inicio",
  "The end date must be before the event availability end date": "La fecha de finalización debe ser anterior a la fecha de finalización de disponibilidad del evento",
  "The end date must be after the event date": "La fecha de finalización debe ser posterior a la fecha del evento",
  "The expiration date must be after both the end of availability date for the ticket type and the event date": "La fecha de vencimiento debe ser posterior tanto a la fecha de fin de disponibilidad del tipo de ticket como a la fecha del evento",
  "The expiration date must be prior to the event end of availability date": "La fecha de vencimiento debe ser anterior a la fecha de fin de disponibilidad del evento",
  "Available tickets cannot be greater than total batch of tickets": "Los boletos disponibles no pueden ser mayores que el lote total de boletos",
  "Max. tickets per user cannot be greater than available tickets": "El número máximo de tickets por usuario no puede ser mayor que el de tickets disponibles",
  "The producer price cannot be higher than the ticket price": "El precio de productor no puede ser mayor que el precio de la entrada",
  "The availability end date cannot be equal to or later than the event availability start date": "La fecha de finalización de disponibilidad no puede ser igual o posterior a la fecha de inicio de disponibilidad del evento",
  "An icon must be selected": "Se debe seleccionar un icono",
  "An error has occurred, the ticket status change could not be completed":"Un error ha ocurrido, no se pudo completar el cambio de estado del ticket",
  "Active ticket":"Ticket activo",
  "Inactive ticket":"Ticket inactivo",
  "Disponibility":"Disponibilidad",
  "SOLD OUT":"AGOTADO",
  "ON SALE":"DISPONIBLE",
  "Sold Out":"Agotado",
  "On Sale":"Disponible",
  "The expiration date must be at least one minute later than ":"La fecha de vencimiento debe ser al menos un minuto mayor que ",
  "Create ticket":"Crear ticket",
  "Gift":"Regalar",
  "The availability end date cannot be later than the event date": "La fecha de finalización de disponibilidad no puede ser posterior a la fecha del evento",
  "The start date of availability must not be greater than the date of the event": "La fecha de inicio de disponibilidad no debe ser posterior a la fecha del evento",
  "The start date must be before the event date": "La fecha de inicio debe ser anterior a la fecha del evento",
  "Value":"Valor",
  "The ID field must be less than 10 characters":"El campo DNI debe tener menos de 10 caracteres",
  "The user has exceeded the number of tickets allowed":"El usuario excede la cantidad de tickets permitidos",
  "There are 0 tickets available of selected type of ticket":"No hay más tickets disponibles para el tipo de ticket seleccionado",
  "An error occurred while trying to create a ticket.":"Ocurrió un error al tratar de crear un ticket ",
  "Quantity is greater than available tickets": "Cantidad mayor que tickets disponibles",
  "The max quantity per user is:":"La máxima cantidad por usuario es:",
  "Error reloading tickets":"Error recargando tickets",
  "Client with DNI":"El cliente con DNI",
  "exceeds max tickets quantity.":"excede la cantidad máxima de tickets",
  "Quantity cannot exceed":"Cantidad no puede exceder",
  "The availability start date must be at least one minute before the event":"La fecha de inicio de disponibilidad debe ser al menos un minuto antes  al evento",
  "The availability start date must not be less than the current one":"La fecha de inicio de disponibilidad no debe ser menor a la actual",
  "The end date must be greater than the start date":"La fecha de finalización debe ser mayor que la fecha de inicio",
  "Enter a valid number": "Ingrese un número válido",
  "Only integer numbers are allowed": "Solo se aceptan números enteros",
  "The end date must be before or equal the event availability end date": "La fecha de finalización debe ser anterior o igual a la fecha de finalización de disponibilidad del evento",
  "The expiration date must be after the event date and before or equal the event availability end date": "La fecha de vencimiento debe ser posterior a la fecha del evento y anterior o igual a la fecha de fin de disponibilidad del evento",
  "ID, Email, name, lastname": "Dni, Email, nombre, apellido",
  "Select the ticket types for the combo": "Seleccione los tipos de tickets para el combo",
  "Select ticket type": "Seleccione tipo de ticket",
  "Add ticket type": "Agregar",
  "Kind of ticket": "Tipo de Ticket",
  "Ticket amount": "Cantidad",
  "Ticket actions": "Acciones",
  "In order to create ticket type combo you must select one ticket type": "Seleccione al menos un tipo de ticket para la categroía 'Combo'",
  "Warning! this ticket type is related to a ticket type 'Combo', if you delete the ticket will be remove from the others": "¡Cuidado! El tipo de ticket que está por eliminar está asociado a otro/s tipos de ticket 'Combo', al borrarlo se eliminarán del tipo de ticket 'Combo'",
  "Redeemed tickets":"Entradas redimidas",
  "All aspects related to redeemed tickets can be viewed from this page":"Todos los aspectos relacionados con las entradas redimidas se pueden visualizar desde esta página",
  "Redemption date":"Fecha de rendención",
  "Redeemed by":"Redimido por",
  "View redeemed tickets": "Visualizar entradas redimidas",
  "An error ocurred when trying to delete ticket type": "Ocurrió un error al intentar eliminar el tipo de ticket. Intentá nuevamente",
  "Ticket type cannot be deleted because is associated to other tickets": "El tipo de ticket no se puede eliminar ya que está asociado a tickets generados",
  //Ventas
  "Sale": "Venta",
  "Select an event and start selling tickets": "Seleccioná un evento y comenzá con la venta de tickets",
  "Select an event": "Seleccioná un evento",
  "No event logs found": "No se han encontrado registros de eventos",
  "Date not available": "Fecha no disponible",
  "TICKET LIST": "LISTADO DE TICKETS",
  "SELL": "VENDER",
  "Sale management": "Gestión de ventas",
  "Sell": "Venta",
  "All aspects related to ticket sales can be managed from this page": "Todos los aspectos relacionados con la venta de tickets se pueden gestionar desde esta página",
  "Camera content": "Contenido de la cámara",
  "Camera": "Cámara",
  "QR Reader": "Lector QR",
  "QR Scan Error:": "Error de escaneo QR:",
  "You must select at least one ticket.": "Debe seleccionar al menos un ticket.",
  "The ID or passport must be between 6 and 20 characters.": "El DNI o pasaporte debe tener entre 6 y 20 caracteres.",
  "Date error": "Error en la fecha",
  "Purchase Date": "Fecha de Compra",
  "Outdate Date": "Fecha de Vencimiento",
// Tickets
  "Anonymous": "Anónimo",
  "Scan the membership card or enter the customer's ID": "Escaneá la tarjeta de socio o ingresá el DNI del cliente",
  "ANONYMOUS CUSTOMER": "CLIENTE ANÓNIMO",
  "An anonymous sale will be recorded. It does not add points or benefits.": "Se registrará una venta anónima. No suma puntos ni beneficios.",
  "An error occurred during ticket sales processing.": "Se produjo un error durante el procesamiento de venta del ticket.",
  "Sale Completed!": "¡Venta Finalizada!",
  "Tickets were generated successfully": "Los tickets fueron generados con éxito",
  "Tickets generated to:" : "Tickets generados a:",
  "Ticket details:": "Detalle de tickets:",
  "IMPORTANT!": "¡IMPORTANTE!",
  "If the customer is a member, their tickets can now be found in the mobile app in the “My Purchases” section": "Si el cliente es socio, sus tickets ya se pueden encontrar en la app mobile en la sección “Mis Compras”",
  "Type of Tickets": "Tipo de Tickets",
  "Qty.": "Cant.",
  "Subtotal": "Subtotal",
  "Ticket information cannot be displayed at this time. Please verify the creation in the tickets list.": "La información del ticket no se puede mostrar en este momento. Por favor, corrobore en el listado de tickets la creación.",
  "Print Tickets": "Imprimir tickets",
  "This user cannot make an anonymous purchase.": "Este usuario no puede realizar compra anónima.",
  "An error occurred when searching for the ID": "A ocurrido un error al buscar el DNI",
  "User authorization cannot be verified": "No se puede verificar la autorización del usuario",
  "Redentions Details": "Detalle de redención",
  "User control": "Usuario Control",
  "Control type": "Tipo de control",
  "Date of control": "Fecha de control",
  "Control point":"Punto de control",
  "Ticket not found" : "Ticket no encontrado",
  "We couldn't find any ticket with movements": "No pudimos encontrar ningun ticket con movimientos",
  "Purchase date: ": "Fecha de Compra: ",
  "Expiration Date: ": "Fecha de Vencimiento: ",
  "The QR code for tickets cannot be displayed at this time.": "No se puede mostrar el QR de las entradas en este momento.",
  "The date cannot be read at this time": "No se puede leer la fecha en este momento",
  "No more tickets can be purchased per user": "No se pueden comprar más tickets por usuario",
  "There are no more tickets available": "No hay más tickets disponibles",
  "You have reached the maximum ticket limit for this type": "Has alcanzado el límite máximo de tickets para este tipo",
  "There are no ticket types available associated with the selected event": "No existen tipos de tickets disponibles asociados al evento seleccionado",
  "Waiting for scan": "Esperando escaneo...",
  "An error occurred when scanning QR": "Ocurrió un error al leer el QR. Porfavor intentá nuevamente.",
  "An error occurred while searching for customer data": "Ocurrió un error al buscar los datos del cliente",
  "User found successfully": "Usuario encontrado exitosamente",
  "User without name":"Usuario sin nombre",

  //Months
  "January": "Enero",
  "February": "Febrero",
  "March": "Marzo",
  "April": "Abril",
  "May": "Mayo",
  "June": "Junio",
  "July": "Julio",
  "August": "Agosto",
  "September": "Septiembre",
  "October": "Octubre",
  "November": "Noviembre",
  "December": "Diciembre",      
  "Sunday": "Domingo",
  "Monday": "Lunes",
  "Tuesday": "Martes",
  "Wednesday": "Miércoles",
  "Thursday": "Jueves",
  "Friday": "Viernes",
  "Saturday": "Sábado",

  //Status
  "Insufficient permissions to enter the module":"Permisos insuficientes para ingresar al módulo.",
};


export default esJSON;
