// En este punto se mapean todas las variables de entorno para que esten Tipadas
//'https://api-refactor-koeiyelafq-uc.a.run.app'
export default {
  API_URL: process.env.REACT_APP_API_URL,
  firebaseConfig : {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  },
  firebase_QP_Config : {
    apiKey: process.env.REACT_APP_FIREBASE_QP_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_QP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_QP_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_QP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_QP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_QP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_QP_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_QP_MEASUREMENT_ID
  }
}
